import { Component, OnInit, Input } from '@angular/core';
import { ActivityTypeModel } from 'src/app/Islamic-Center/Activites/ActivityType/ActivityTypeModel';
import { ActivityTypeService } from 'src/app/Islamic-Center/Activites/ActivityType/activity-type.service';
import { ContactsModel } from 'src/app/Islamic-Center/Master-Data/Contacts/ContactsModel';
import { ContactUsService } from 'src/app/Islamic-Center/Master-Data/Contacts/contact-us.service';
import { FooterService } from './footer.service';
import { FooterModel } from './FooterModel';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/logo icon.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True
  lang='';
  public today: number = Date.now();
  ContactUsList:ContactsModel[]=[];

  constructor( private modelService :ContactUsService,private ActivityTypeService:ActivityTypeService,
    private FooterService:FooterService) { }
ActivityTypeList:ActivityTypeModel[]=[];
FooterList:FooterModel[]=[];
  ngOnInit(): void {
    this.modelService.getAll().subscribe(data=>{
      this.ContactUsList=data;
  })
    this.lang= localStorage.getItem('theme_lang');
    this.ActivityTypeService.getAll().subscribe(data=>{
      this.ActivityTypeList=data;
      //console.log("ActivityTypeList",this.ActivityTypeList);
    })
    this.FooterService.getAll().subscribe(data=>{
      this.FooterList=data;
    })
  }

}
